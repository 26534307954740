<template>
  <App_frame tab_name="3">
    <div style="text-align: left;padding: 0 40px" class="d_flex">
      <div>
        <p class="title" style="font-size: 20px">
          情报订阅
          <el-button @click="dialogTableVisible = true" type="primary" style="margin-left: 50px">新增订阅</el-button>
        </p>
        <p>订阅记录</p>
      </div>
      <el-divider />
      <el-table height="calc(100vh - 230px)" :data="tableData" empty-text="-" style="width: 100%">
        <el-table-column type="index" label="ID" width="50" />
        <el-table-column prop="subject" label="订阅主题" min-width="180" />
        <el-table-column prop="category" label="分类类标" min-width="180">
          <template v-slot="{ row }">
            {{row.category.length ? row.category.join('，') : '-'}}
          </template>
        </el-table-column>
        <el-table-column prop="common_labels" label="通用标签" min-width="150">
          <template v-slot="{ row }">
            {{row.common_labels.length ? row.common_labels.join('，') : '-'}}
          </template>
        </el-table-column>
        <el-table-column prop="org_labels" label="机构" min-width="150">
          <template v-slot="{ row }">
            {{row.org_labels.length ? row.org_labels.join('，') : '-'}}
          </template>
        </el-table-column>
        <el-table-column prop="drug_labels" label="药物" min-width="150">
          <template v-slot="{ row }">
            {{row.drug_labels.length ? row.drug_labels.join('，') : '-'}}
          </template>
        </el-table-column>
        <el-table-column prop="target_labels" label="靶点" min-width="150">
          <template v-slot="{ row }">
            {{row.target_labels.length ? row.target_labels.join('，') : '-'}}
          </template>
        </el-table-column>
        <el-table-column prop="indication_labels" label="适应症" min-width="150">
          <template v-slot="{ row }">
            {{row.indication_labels.length ? row.indication_labels.join('，') : '-'}}
          </template>
        </el-table-column>
        <el-table-column prop="created_at" label="订阅时间" width="150">
          <template v-slot="{ row }">
            {{dayjs(row.created_at).format('YYYY-MM-DD')}}
          </template>
        </el-table-column>
        <el-table-column prop="status" label="当前状态" width="180">
          <template v-slot="{ row }">
            {{row.status ? '订阅中' : '断订'}}
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="100">
          <template v-slot="{ row }">
            <el-button @click="chenge_status(row.id,row.status)" type="primary" link>
              {{row.status ? '取消订阅' : '续订'}}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination :current-page="current_page" :page-size="page_size" :page-sizes="[20, 40, 60, 80, 100]"
                       :total="dataTotal"
                       size="small"
                       align="center" layout="total, sizes, prev, pager, next, jumper"
                       @size-change="page_size_change" @current-change="current_page_change">
        </el-pagination>
      </div>
      <New_subscription v-if="dialogTableVisible" v-model:isdialog="dialogTableVisible" @send-visible="change_visibel" />
    </div>
  </App_frame>
</template>

<script setup>
import {ref, onMounted} from "vue";
import {get_subscribe, modify_subscribe} from "@/api/data.js";
import {dayjs} from "element-plus";

const tableData = ref([])
const dataTotal = ref(0)
const current_page = ref(1)
const page_size = ref(20)
const dialogTableVisible = ref(false)

const change_visibel = ({visible,success})=>{
  dialogTableVisible.value = visible
  if(success === true){
    ElMessage({
      message: '订阅成功',
      type: 'success',
    })
  }
  update_data()
}
const page_size_change = (val) => {
  page_size.value = val
  update_data()
}
const current_page_change = (val) => {
  current_page.value = val
  update_data()
}
const update_data = ()=>{
  get_subscribe(current_page.value, page_size.value)
      .then(res=>{
        dataTotal.value = res.data.total
        tableData.value = res.data.results
      })
}
const chenge_status = (id, type) => {
  modify_subscribe(id)
      .then(res=>{
        update_data()
        if(type){
          ElMessage({
            message: '退订成功',
            type: 'success',
          })
        } else {
          ElMessage({
            message: '续订成功',
            type: 'success',
          })
        }
      })
      .catch(()=>{
        if(type){
          ElMessage.error('退订失败')
        } else {
          ElMessage.error('续订失败')
        }
      })
}

onMounted(()=>{
  update_data()
})

</script>

<style scoped>
.title{
  padding: 20px 0;
  font-size: 20px
}
:deep(.el-divider--horizontal){
  margin: 8px 0;
}
.pagination{
  display: flex;
  justify-content: center;
}
</style>