<template>
  <el-dialog v-model="dialogTableVisible" :before-close="close_visible"  width="600" title="新增订阅" :close-on-click-modal="false" :close-on-press-escape="false" :how-close="false">
    <div style="padding: 0 10px">
      <p class="title">添加关键词</p>
      <div style="display: flex">
        <el-select
            v-model="search_input"
            filterable
            remote
            reserve-keyword
            placeholder="可以是药物、靶点、机构、适应症或其他关键字"
            :remote-method="remoteMethod"
            :loading="loading"
        >
          <el-option
              v-for="item in options"
              :key="item.label"
              :value="item.cate + ' - ' + item.label"
              :label="item.label"
          >
            <div class="d-flex" style="align-items: center">
              <el-text type="danger">{{label_name[item.cate]}}</el-text>
              ·
              <span>{{ item.label }}</span>
            </div>
          </el-option>
        </el-select>
        <el-button @click="add_label(search_input)" style="margin-left: 12px" type="primary">添加</el-button>
      </div>
      <p class="title">分类类型</p>
      <el-checkbox-group v-model="submit_list.category_list">
        <el-checkbox label="研发" value="研发" />
        <el-checkbox label="临床" value="临床" />
        <el-checkbox label="交易" value="交易" />
        <el-checkbox label="其他" value="其他" />
      </el-checkbox-group>
      <div v-for="(items,key) in sort_list">
        <p class="title">
          <span v-if="submit_list[items].length && key !== 0">
            {{label_name[items.split('_')[0]] + '标签'}}：
            <el-tag
                v-for="item in submit_list[items]"
                closable
                :disable-transitions="false"
                @close="handleClose(item,items)"
                effect="dark"
            >{{item}}</el-tag>
          </span>
        </p>
      </div>
      <p class="title">发送给</p>
      <el-checkbox-group v-if="email" v-model="is_oneself">
        <el-checkbox :label="email+'(自己)'" :value="email" />
      </el-checkbox-group>
      <el-input
          v-model="mailbox_textarea"
          :rows="2"
          type="textarea"
          placeholder="请输入收件人邮箱地址，多个邮箱之间请回车分隔"
      />
      <p class="title">提醒频次</p>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-select v-model="frequency_value" @change="get_date_option" placeholder="Select">
            <el-option
                v-for="item in frequency_option"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            />
          </el-select>
        </el-col>
        <el-col v-if="frequency_value !== 'daily'" :span="12">
          <el-select v-model="date_value" placeholder="Select">
            <el-option
                v-for="item in date_option"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            />
          </el-select>
        </el-col>
      </el-row>
      <p class="title">订阅主题</p>
      <el-input v-model="subject_input" placeholder="为邮件主题" />
    </div>
    <template v-slot:footer>
      <el-button @click="close_visible">取消</el-button>
      <el-button @click="subscribe_create" type="primary">确认</el-button>
    </template>
  </el-dialog>
</template>

<script setup>
import {create_subscribe, labels_fuzz} from "@/api/data.js";
import {ref, defineProps, defineEmits, reactive, onMounted} from "vue";
import {
  frequency_option,
  weekly_option,
  biweekly_option,
  monthly_option,
  label_name,
  sort_list
} from "../common/results_data.js";

const props = defineProps({
  label_list: Object,
  query_list: Object
});
const email = localStorage.getItem('email');
const dialogTableVisible = ref(true)
const search_input = ref('')
const options = ref([])
const loading = ref(false)
const subject_input = ref('')
const mailbox_textarea = ref('')
const frequency_value = ref('weekly')
const date_value = ref('{"first": ["monday"]}')
const date_option = ref(weekly_option)
const is_oneself = ref(email ? [email] : [])
const query_list =  reactive({
  category_list: [],
  common_list: [],
  drug_list: [],
  target_list: [],
  indication_list: [],
  org_list: [],
})
const submit_list = reactive({
  category_list: [],
  common_list: [],
  drug_list: [],
  target_list: [],
  indication_list: [],
  org_list: [],
})
const remoteMethod = (query) => {
  if (query) {
    loading.value = true
    labels_fuzz(query)
        .then(res =>{
          loading.value = false
          options.value = res.data.labels
        })
  } else {
    options.value = []
  }
}

const add_label = (data) =>{
  console.log(data)
  console.log(data.split(' - ')[0] + '_list')
  console.log(data.split(' - ')[1])
  if(data){
    if(!submit_list[data.split(' - ')[0] + '_list'].includes(data)){
      submit_list[data.split(' - ')[0] + '_list'].push(data.split(' - ')[1])
    }
  } else {
  }
  search_input.value = ''
}

const emit = defineEmits(['send-visible']);

const close_visible = (val = false) =>{
  emit('send-visible', {
    visible: false,
    success: val
  });
}
const subscribe_create = ()=>{
  let strArray = []
  if(mailbox_textarea.value){
    strArray = mailbox_textarea.value.split('\n')
  }
  const newArr = [...is_oneself.value, ...strArray]
  const schedule = {
    type: frequency_value.value,
    when: frequency_value.value === 'daily' ? {} : JSON.parse(date_value.value)
  }
  console.log(schedule)
  create_subscribe(JSON.stringify({
    subject: subject_input.value,
    tos: newArr,
    schedule,
    status: 1,
    // category: submit_list.category_list,
    // common_labels: submit_list.common_list,
    // drug_labels: submit_list.drug_list,
    // target_labels: submit_list.target_list,
    // indication_labels: submit_list.indication_list,
    // org_labels: submit_list.org_list,
    category: submit_list.category_list.concat(query_list.category_list),
    common_labels: submit_list.common_list.concat(query_list.common_list),
    drug_labels: submit_list.drug_list.concat(query_list.drug_list),
    target_labels: submit_list.target_list.concat(query_list.target_list),
    indication_labels: submit_list.indication_list.concat(query_list.indication_list),
    org_labels: submit_list.org_list.concat(query_list.org_list),
  }))
      .then(res=>{
        close_visible(true)
      })
      .catch(err=> {
        ElMessage.error('订阅失败')
      })
}
const get_date_option = (val) => {
  if(frequency_value.value !== 'daily'){
    const variableName = `${val}_option`
    date_option.value = eval(variableName)
    date_value.value = date_option.value[0].value
  }
}
const handleClose = (val, name) => {
  submit_list[name] = submit_list[name].filter(item => item !== val)
}

onMounted(()=>{
  if(props.label_list){
    Object.assign(submit_list, {...props.label_list})
  }
  if(props.query_list){
    Object.assign(query_list, {...props.query_list})
  }
})
</script>

<style scoped>
.title{
  margin-top: 20px;
  margin-bottom: 10px;
}
</style>