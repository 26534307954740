export const sort_label = [
    'category',
    'common_labels',
    'drug_labels',
    'target_labels',
    'indication_labels',
    'org_labels'
]
export const sort_list = [
    'category_list',
    'common_list',
    'drug_list',
    'target_list',
    'indication_list',
    'org_list'
]
export const category_list = {
    临床: '临床',
    其他: '其他',
    药物研发: '研发',
    交易: '交易',
}
export const label_name = {
    common: '通用',
    drug: '药物',
    target: '靶点',
    indication: '适应症',
    org: '机构'
}
export const frequency_option = [
    {
        label: '每天',
        value: 'daily'
    },
    {
        label: '每周',
        value: 'weekly'
    },
    {
        label: '每两周',
        value: 'biweekly'
    },
    {
        label: '每月',
        value: 'monthly'
    },
]
export const weekly_option = [
    {
        label: '周一',
        value: '{"first": ["monday"]}'
    },
    {
        label: '周二',
        value: '{"first": ["tuesday"]}'
    },
    {
        label: '周三',
        value: '{"first": ["wednesday"]}'
    },
    {
        label: '周四',
        value: '{"first": ["thursday"]}'
    },
    {
        label: '周五',
        value: '{"first": ["friday"]}'
    },
    {
        label: '周六',
        value: '{"first": ["Saturday"]}'
    },
    {
        label: '周日',
        value: '{"first": ["Sunday"]}'
    },
]
export const biweekly_option = [
    {
        label: '第1周周一',
        value: '{"first": ["monday"]}'
    },
    {
        label: '第1周周二',
        value: '{"first": ["tuesday"]}'
    },
    {
        label: '第1周周三',
        value: '{"first": ["wednesday"]}'
    },
    {
        label: '第1周周四',
        value: '{"first": ["thursday"]}'
    },
    {
        label: '第1周周五',
        value: '{"first": ["friday"]}'
    },
    {
        label: '第1周周六',
        value: '{"first": ["Saturday"]}'
    },
    {
        label: '第1周周日',
        value: '{"first": ["Sunday"]}'
    },
    {
        label: '第2周周一',
        value: '{"second": ["monday"]}'
    },
    {
        label: '第2周周二',
        value: '{"second": ["tuesday"]}'
    },
    {
        label: '第2周周三',
        value: '{"second": ["wednesday"]}'
    },
    {
        label: '第2周周四',
        value: '{"second": ["thursday"]}'
    },
    {
        label: '第2周周五',
        value: '{"second": ["friday"]}'
    },
    {
        label: '第2周周六',
        value: '{"second": ["Saturday"]}'
    },
    {
        label: '第2周周日',
        value: '{"second": ["Sunday"]}'
    },
]
export const monthly_option = [
    {
        label: '1日',
        value: '{"days": [1]}'
    },
    {
        label: '2日',
        value: '{"days": [2]}'
    },
    {
        label: '3日',
        value: '{"days": [3]}'
    },
    {
        label: '4日',
        value: '{"days": [4]}'
    },
    {
        label: '5日',
        value: '{"days": [5]}'
    },
    {
        label: '6日',
        value: '{"days": [6]}'
    },
    {
        label: '7日',
        value: '{"days": [7]}'
    },
    {
        label: '8日',
        value: '{"days": [8]}'
    },
    {
        label: '9日',
        value: '{"days": [9]}'
    },
    {
        label: '10日',
        value: '{"days": [10]}'
    },
    {
        label: '11日',
        value: '{"days": [11]}'
    },
    {
        label: '12日',
        value: '{"days": [12]}'
    },
    {
        label: '13日',
        value: '{"days": [13]}'
    },
    {
        label: '14日',
        value: '{"days": [14]}'
    },
    {
        label: '15日',
        value: '{"days": [15]}'
    },
    {
        label: '16日',
        value: '{"days": [16]}'
    },
    {
        label: '17日',
        value: '{"days": [17]}'
    },
    {
        label: '18日',
        value: '{"days": [18]}'
    },
    {
        label: '19日',
        value: '{"days": [19]}'
    },
    {
        label: '20日',
        value: '{"days": [20]}'
    },
    {
        label: '21日',
        value: '{"days": [21]}'
    },
    {
        label: '22日',
        value: '{"days": [22]}'
    },
    {
        label: '23日',
        value: '{"days": [23]}'
    },
    {
        label: '24日',
        value: '{"days": [24]}'
    },
    {
        label: '25日',
        value: '{"days": [25]}'
    },
    {
        label: '26日',
        value: '{"days": [26]}'
    },
    {
        label: '27日',
        value: '{"days": [27]}'
    },
    {
        label: '28日',
        value: '{"days": [28]}'
    },
    {
        label: '29日',
        value: '{"days": [29]}'
    },
    {
        label: '30日',
        value: '{"days": [30]}'
    },
    {
        label: '31日',
        value: '{"days": [31]}'
    },
]
